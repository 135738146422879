import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Layout from '@src/components/layout';
import { pages } from '@src/constants/pages';
import Challenge from '@src/components/shared/challenge';
import Gallery from '@src/components/shared/gallery';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

const Capabilities = () => (
  <Layout pageName={pages.capabilities.name}>
    <div className="with-bottom-decoration">
      <h1 className="h-center">Capabilities</h1>
      <div className="subheading">
        PCB assembly capabilities
      </div>
    </div>
    <section className="c-content-centered h-pt-40 h-pb-40">
      <h3>Conformal Coating of Circuit Board Assemblies</h3>
      <p>
        There are many applications that require the use of conformal coatings to provide protection
        from deleterious elements. We are experienced in meeting the challenges of coating
        assemblies with unusual configurations and extensive keep out areas.
      </p>
      <p>
        Contact us to recommend a coating that will meet your field requirements or we can work with
        the material of your choice.
      </p>
      <h3 className="h-mt-40">Automated Optical Inspection of Circuit Board Assemblies</h3>
      <p>
        We have the latest technology that is available for the Automated Optical Inspection of
        circuit board assemblies. Our new systems perform <strong>3D inspection</strong> which is
        the only reliable method to detect lifted leads and to perform BGA co-planarity
        inspections. For standard devices and increased thoughtput, these state of the art AOI
        machines also do 2D inspections using a high resolution 12 megapixel camera and 3 different
        colors. This new generation of AOI machines allows for precise inspection of the smallest
        components such as 01005&apos;s for improved reliability.
      </p>
      <p>
        Overall, ADCO provides flawless circuit board assemblies as a result of AOI equipment that
        has extremely low false call rates. This equipment is interfaced to a data collection system
        that visually alerts the line to out of tolerance conditions that manufacturing engineers
        need to review for corrective actions or temporary line shut down.
      </p>
      <h3 className="h-mt-40">Selective Soldering</h3>
      <p>
        ADCO has two Ersa selective solder machines to enhance throughput of circuit board
        assemblies with active parts on both sides of the circuit board and/or just a few through
        hole components. Most modern surface mount designs are uniquely qualified for selective
        soldering. This eliminates costly hand soldering and wave solder pallets.
      </p>
      <p>
        The Ersa machines perform point to point soldering in a flexible production cell that
        produces a class 3 quality solder joint without a dedicated robot. Due to its modular design
        these machines are optimized for throughput with three independent conveyors for
        fluxing, preheating, and soldering.
      </p>
      <h3 className="h-mt-40">Supply Chain &amp; Order Fulfillment</h3>
      <h4 className="h-mt-20 h-mb-20">
        Robust Systems that Provide Lowest Cost and On Time Delivery
      </h4>
      <p>
        Key elements of our procurement strategy are designed to provide the lowest possible costed
        bills of material. Starting at the quotation stage our purchasing team uses a web enabled
        software tool to quote all franchised component vendors at the push of a button. For custom
        components such as circuitboards and transformers we identify the most cost competitive
        source from a list of qualified vendors that can provide the volume profile and fabrication
        technology needed to meet specification.
      </p>
      <p>
        Once a contract is awarded, a structured plan for procurement is established and buyers are
        assigned to individual customers who monitor supplier performance for on time delivery and
        quality. The customer dedicated buyer has ERP software tools that supports identification
        and resolution of spot material shortages. For ongoing purchase order maintenance, our
        EPICOR Vantage ERP system helps leverage buyer resources by providing suppliers with an
        online forecast and replenishment pull signals.
      </p>
      <h4 className="h-mt-20 h-mb-20">Flexible Inventory and Shipping Arrangements</h4>
      <p>
        For many ADCO customers, we are their virtual warehouse. This happens either through kan-ban
        replenishment systems or order fulfillment services. Upon customer direction we can also
        ship to the end customer using specific packaging materials and designs. When we make the
        shipment, key data such as serial numbers, MAC Address, and IMEI numbers are included on
        pack lists and automated emails.
      </p>
      <p>Order fulfillment services include:</p>
      <ul>
        <li>Warehousing finished goods</li>
        <li>Final product configuration before shipment</li>
        <li>
          Aligning information technology (IT) systems for seamless shipping instructions and
          communication
        </li>
        <li>Use of customer specified packaging materials</li>
        <li>Shipments worldwide</li>
        <li>Same day shipping</li>
        <li>Warranty and depot repair services</li>
      </ul>
    </section>
    <Gallery />
    <Challenge
      onStartQuotingClick={customEvent(events.capabilitiesStartQuoting())}
      onContactUsClick={customEvent(events.capabilitiesContactUs())}
    />
  </Layout>
);

export default Capabilities;
